export const TOGGLE_SAVE = 'TOGGLE_SAVE'
export const TOGGLE_SHOW_SAVE = 'TOGGLE_SHOW_SAVE'
export const FETCH_HAS_ERRORED = 'FETCH_HAS_ERRORED'
export const FETCH_IS_LOADING = 'FETCH_IS_LOADING'
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS'
export const FETCH_SHOWS_SUCCESS = 'FETCH_SHOWS_SUCCESS'
export const FETCH_CAST_SUCCESS = 'FETCH_CAST_SUCCESS'
export const FETCH_CURRENT_MOVIE = 'FETCH_CURRENT_MOVIE'
export const FETCH_CURRENT_SHOW = 'FETCH_CURRENT_SHOW'
export const TOGGLE_DETAIL_SAVE = 'TOGGLE_DETAIL_SAVE'
export const TOGGLE_DETAIL_SHOW = 'TOGGLE_DETAIL_SHOW'
